<template>
  <dashboard>
    <el-row :gutter="15">
      <el-col :xs="24" :md="16">
        <div class="">
          <el-alert
            v-if="latestHiredNotice && latestHiredNotice.employer"
            class="mb-3 success"
            title="You have been hired by Employer"
            type="success"
            @close="closeHireNotice"
          >
            <span class="d-block mb-2">
              Congratulations! You just landed a job at
              {{ latestHiredNotice.company && latestHiredNotice.company.company_name ? latestHiredNotice.company.company_name : latestHiredNotice.employer.full_name }}
              on {{ latestHiredNotice.created_at | longHumanDate }}.
              Please make sure to coordinate with them for further instructions.
            </span>
            <center>
              <el-button size="mini" class="block mt-3 mb-1" type="success" @click="closeHireNotice">Close</el-button>
            </center>
          </el-alert>
          <!-- FOR BACKEND INTEGRATION: This card should show when the freelancer has not completed the profile-->
          <el-card class="admin-message mb-3">
            <img src="/images/welcome.png" alt="" class="w-75 mb-3 mx-auto d-block">
            <div v-if="progress" class="height-transition">
              <p class="bold">Do your best in filling-up your profile to increase your chances of getting hired!</p>
            </div>
            <div v-if="progress" class="text-center pulse">
              <el-button type="warning" class="feed-btn" @click="$router.push('/accountdetails')">Update Your Profile</el-button>
            </div>
          </el-card>
          <!-- FOR BACKEND INTEGRATION: This card should show when the freelancer has completed the profile-->
          <!-- <el-card class="admin-message mb-3">
            <img src="/images/welcome.png" alt="" class="w-75 mb-3 mx-auto d-block">
            <div class="height-transition">
              <p class="bold">Thank you for completing your profile. Check our job page to see the list of job postings.</p>
            </div>
            <div class="text-center">
              <el-button type="success" @click="$router.push('/freelancer/jobs')">Search for Jobs</el-button>
            </div> 
          </el-card>-->
          <el-card class="jobfeed mb-3" v-if="jobs > 0">
            <div class="header">
              <h5><i class="el-icon-s-tools" /> Jobs that might interest you</h5>
            </div>
            <div class="body">
              <!-- <p class="small mb-3 mt-3">We have not yet opened up for the employers to post jobs, however, you are welcome to browse a couple of openings we have pre-launch.</p> -->
              <freelancer-job-list :jobs="jobs" />
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :xs="24" :md="8">
        <notifications />
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Dashboard from '~/components/Dashboard/Dashboard'
import Notifications from '~/components/Messenger/Notifications'
import FreelancerJob from '~/models/Jobs/FreelancerJob'
import FreelancerJobList from '~/components/Jobs/Freelancer/List'
import MyFreelancerProfile from '~/models/MyFreelancerProfile'

export default {
  components: {
    Dashboard,
    Notifications,
    FreelancerJobList,
  },

  filters: {
    longHumanDate (value) {
      return moment.utc(value).local().format('LL')
    },
  },

  data () {
    return {
      jobs: [],
      latestHiredNotice: {},
    }
  },

  created () {
    FreelancerJob.search({ per_page: 5 }).then(response => {
      this.jobs = response.data.data
    })
    MyFreelancerProfile.latestHiredNotice().then(response => {
      this.latestHiredNotice = response.data
    })
  },

  methods: {
    closeHireNotice () {
      this.latestHiredNotice = null
      MyFreelancerProfile.closeHiredNotice()
    },
  },

  computed : {
    ...mapGetters({
      progress: 'getFreelancerProfileProgress',
    }),
  }
}
</script>

<style lang="scss">
  
</style>
